import React, { useState, useCallback, useEffect } from 'react';
import { Upload, X } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function ImageUploadPage() {
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const handleDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  }, []);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  }, []);

  const removeFile = useCallback(() => {
    setFile(null);
  }, []);

  useEffect(() => {
    const handleWindowDragOver = (e: DragEvent) => {
      e.preventDefault();
    };

    const handleWindowDrop = (e: DragEvent) => {
      e.preventDefault();
      if (e.dataTransfer?.files && e.dataTransfer.files[0]) {
        setFile(e.dataTransfer.files[0]);
      }
    };

    window.addEventListener('dragover', handleWindowDragOver);
    window.addEventListener('drop', handleWindowDrop);

    return () => {
      window.removeEventListener('dragover', handleWindowDragOver);
      window.removeEventListener('drop', handleWindowDrop);
    };
  }, []);

  return (
    <div
      className="min-h-screen w-full bg-gradient-to-br from-gray-900 to-gray-800 text-white flex flex-col items-center justify-center font-sans overflow-hidden p-4"
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <h1 className="text-4xl font-extrabold text-center mb-4 text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-pink-600">
        Owtatics
      </h1>

      <div className="text-center text-lg text-white font-medium mb-8 max-w-2xl mx-auto">
        <p className="mb-2">Send a screenshot of your Overwatch 2 match showing the scoreboard (TAB)</p>
      </div>

      <div className="w-full max-w-5xl flex flex-col md:flex-row gap-6 mb-8">
        <div
          className={`w-full md:w-1/2 p-6 rounded-xl transition-all duration-300 ${
            dragActive ? 'bg-blue-500 bg-opacity-20 border-2 border-blue-500' : 'bg-gray-700 border-2 border-gray-600'
          }`}
        >
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-full cursor-pointer"
          >
            {file ? (
              <div className="flex flex-col items-center">
                <img src={URL.createObjectURL(file)} alt="Uploaded file" className="max-h-64 mb-4 rounded-lg" />
                <p className="text-sm text-gray-300">{file.name}</p>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    removeFile();
                  }}
                  className="mt-2 flex items-center text-red-400 hover:text-red-300"
                >
                  <X className="w-4 h-4 mr-1" />
                  Remove
                </button>
              </div>
            ) : (
              <>
                <Upload className="w-16 h-16 mb-4 text-gray-400" />
                <p className="mb-2 text-lg text-gray-300 text-center">
                  <span className="font-bold">Click to upload</span> or drag and drop
                </p>
                <p className="text-sm text-gray-400">PNG, JPG, GIF up to 10MB</p>
              </>
            )}
          </label>
          <input id="dropzone-file" type="file" className="hidden" onChange={handleChange} accept="image/*" />
        </div>

        <div className="w-full md:w-1/2 bg-gray-700 border-2 border-gray-600 rounded-xl overflow-hidden relative">
          <div className="absolute top-0 left-0 right-0 bg-blue-600 text-white text-center py-2 font-medium text-lg">
            Example
          </div>
          <img
            src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-M1jwgyyjCHJb08mPVmaxJFob65ZpE2.png"
            alt="Example of Overwatch 2 statistics"
            className="w-full h-full object-cover pt-10"
          />
        </div>
      </div>

      {/* Updated "Go to Main Page" button */}
      <Link
        to="/"
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg shadow-md transition-all duration-300"
      >
        Go to Main Page
      </Link>
    </div>
  );
}
