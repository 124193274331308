import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HeroSelectPage from './pages/HeroSelectPage';
import ImageUploadPage from './pages/ImageUploadPage';

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HeroSelectPage />} />
        <Route path="/screenshot" element={<ImageUploadPage />} />
      </Routes>
    </Router>
  );
}
