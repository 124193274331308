import 'tailwindcss/tailwind.css';
import React, { useState } from 'react';
import { Shield, Swords, Heart, Send, Trash2 } from 'lucide-react';

// Interfaces for Hero, TeamSlot, and HeroSuggestion
interface Hero {
  id: number;
  name: string;
  role: 'Tank' | 'Damage' | 'Support';
  image: string;
}

interface TeamSlot {
  role: 'Tank' | 'Damage' | 'Support';
  hero: Hero | null;
}

interface HeroSuggestion {
  hero: string;
  justification: string;
}

// List of heroes
const heroes: Hero[] = [
  { id: 1, name: "Ana", role: "Support", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/3429c394716364bbef802180e9763d04812757c205e1b4568bc321772096ed86.png" },
  { id: 2, name: "Ashe", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/8dc2a024c9b7d95c7141b2ef065590dbc8d9018d12ad15f76b01923986702228.png" },
  { id: 3, name: "Baptiste", role: "Support", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f979896f74ba22db2a92a85ae1260124ab0a26665957a624365e0f96e5ac5b5c.png" },
  { id: 4, name: "Bastion", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/4d715f722c42215072b5dd0240904aaed7b5285df0b2b082d0a7f1865b5ea992.png" },
  { id: 5, name: "Brigitte", role: "Support", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/48392820c6976ee1cd8dde13e71df85bf15560083ee5c8658fe7c298095d619a.png" },
  { id: 6, name: "Cassidy", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/6cfb48b5597b657c2eafb1277dc5eef4a07eae90c265fcd37ed798189619f0a5.png" },
  { id: 7, name: "D.Va", role: "Tank", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/ca114f72193e4d58a85c087e9409242f1a31e808cf4058678b8cbf767c2a9a0a.png" },
  { id: 8, name: "Doomfist", role: "Tank", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/13750471c693c1a360eb19d5ace229c8599a729cd961d72ebee0e157657b7d18.png" },
  { id: 9, name: "Echo", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f086bf235cc6b7f138609594218a8385c8e5f6405a39eceb0deb9afb429619fe.png" },
  { id: 10, name: "Genji", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/4edf5ea6d58c449a2aeb619a3fda9fff36a069dfbe4da8bc5d8ec1c758ddb8dc.png" },
  { id: 11, name: "Hanzo", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/aecd8fa677f0093344fab7ccb7c37516c764df3f5ff339a5a845a030a27ba7e0.png" },
  { id: 12, name: "Illari", role: "Support", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/5ea986038f9d307bd4613d5e6f2c4c8e7f15f30ceeeabbdd7a06637a38f17e1f.png" },
  { id: 13, name: "Junker Queen", role: "Tank", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/cef2406b2244b80506f83b8fb9ebaf214f41fa8795cbeef84026cd8018561d04.png" },
  { id: 14, name: "Junkrat", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/037e3df083624e5480f8996821287479a375f62b470572a22773da0eaf9441d0.png" },
  { id: 15, name: "Kiriko", role: "Support", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/088aff2153bdfa426984b1d5c912f6af0ab313f0865a81be0edd114e9a2f79f9.png" },
  { id: 16, name: "Lifeweaver", role: "Support", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/39d4514f1b858bc228035b09d5a74ed41f8eeefc9a0d1873570b216ba04334df.png" },
  { id: 17, name: "Lúcio", role: "Support", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/e2ff2527610a0fbe0c9956f80925123ef3e66c213003e29d37436de30b90e4e1.png" },
  { id: 18, name: "Mei", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/1533fcb0ee1d3f9586f84b4067c6f63eca3322c1c661f69bfb41cd9e4f4bcc11.png" },
  { id: 19, name: "Mercy", role: "Support", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/2508ddd39a178d5f6ae993ab43eeb3e7961e5a54a9507e6ae347381193f28943.png" },
  { id: 20, name: "Moira", role: "Support", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/000beeb5606e01497897fa9210dd3b1e78e1159ebfd8afdc9e989047d7d3d08f.png" },
  { id: 21, name: "Orisa", role: "Tank", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/71e96294617e81051d120b5d04b491bb1ea40e2933da44d6631aae149aac411d.png" },
  { id: 22, name: "Pharah", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f8261595eca3e43e3b37cadb8161902cc416e38b7e0caa855f4555001156d814.png" },
  { id: 23, name: "Ramattra", role: "Tank", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/3e0367155e1940a24da076c6f1f065aacede88dbc323631491aa0cd5a51e0b66.png" },
  { id: 24, name: "Reaper", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/2edb9af69d987bb503cd31f7013ae693640e692b321a73d175957b9e64394f40.png" },
  { id: 25, name: "Reinhardt", role: "Tank", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/490d2f79f8547d6e364306af60c8184fb8024b8e55809e4cc501126109981a65.png" },
  { id: 26, name: "Roadhog", role: "Tank", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/72e02e747b66b61fcbc02d35d350770b3ec7cbaabd0a7ca17c0d82743d43a7e8.png" },
  { id: 27, name: "Sigma", role: "Tank", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/cd7a4c0a0df8924afb2c9f6df864ed040f20250440c36ca2eb634acf6609c5e4.png" },
  { id: 28, name: "Sojourn", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a53bf7ad9d2f33aaf9199a00989f86d4ba1f67c281ba550312c7d96e70fec4ea.png" },
  { id: 29, name: "Soldier: 76", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/20b4ef00ed05d6dba75df228241ed528df7b6c9556f04c8070bad1e2f89e0ff5.png" },
  { id: 30, name: "Sombra", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/bca8532688f01b071806063b9472f1c0f9fc9c7948e6b59e210006e69cec9022.png" },
  { id: 31, name: "Symmetra", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/7f2024c5387c9d76d944a5db021c2774d1e9d7cbf39e9b6a35b364d38ea250ac.png" },
  { id: 32, name: "Torbjörn", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/1309ab1add1cc19189a2c8bc7b1471f88efa1073e9705d2397fdb37d45707d01.png" },
  { id: 33, name: "Tracer", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a66413200e934da19540afac965cfe8a2de4ada593d9a52d53108bb28e8bbc9c.png" },
  { id: 34, name: "Widowmaker", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a714f1cb33cc91c6b5b3e89ffe7e325b99e7c89cc8e8feced594f81305147efe.png" },
  { id: 35, name: "Winston", role: "Tank", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/bd9c8e634d89488459dfc1aeb21b602fa5c39aa05601a4167682f3a3fed4e0ee.png" },
  { id: 36, name: "Wrecking Ball", role: "Tank", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/5c18e39ce567ee8a84078f775b9f76a2ba891de601c059a3d2b46b61ae4afb42.png" },
  { id: 37, name: "Zarya", role: "Tank", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/8819ba85823136640d8eba2af6fd7b19d46b9ee8ab192a4e06f396d1e5231f7a.png" },
  { id: 38, name: "Zenyatta", role: "Support", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/71cabc939c577581f66b952f9c70891db779251e8e70f29de3c7bf494edacfe4.png" },
  { id: 39, name: "Mauga", role: "Tank", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/9ee3f5a62893091d575ec0a0d66df878597086374202c6fc7da2d63320a7d02e.png" },
  { id: 40, name: "Venture", role: "Damage", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/7e33dd756c8a1abca519af6c3bf26813f2f81d39885373539efcf8442c4bc647.png" },
  { id: 41, name: "Juno", role: "Support", image: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/585b2d60cbd3c271b6ad5ad0922537af0c6836fab6c89cb9979077f7bb0832b5.png" },
];

// List of maps
const maps = [
  "Samoa",
  "Junkertown",
  "Colosseo",
  "Route 66",
  "King's Row",
  "Numbani",
  "Havana",
  "Ilios",
  "Nepal",
  "Lijiang",
  "Busan",
  "Hollywood",
  "Eichenwalde",
  "Temple of Anubis",
  "Volskaya Industries",
  "Dorado",
  "Paris",
  "Oasis",
  "Watchpoint: Gibraltar",
  "Blizzard World",
  "Circuit Royal",
  "New Queen Street",
  "Antarctica",
];

// RoleIcon component for rendering icons
const RoleIcon: React.FC<{ role: 'Tank' | 'Damage' | 'Support'; size?: number }> = ({ role, size = 5 }) => {
  const icons = {
    Tank: <Shield className={`w-${size} h-${size} text-white`} />,
    Damage: <Swords className={`w-${size} h-${size} text-white`} />,
    Support: <Heart className={`w-${size} h-${size} text-white`} />,
  };
  return icons[role] || null;
};

const Component: React.FC = () => {
  const initialSlots: TeamSlot[] = [
    { role: 'Tank', hero: null },
    { role: 'Damage', hero: null },
    { role: 'Damage', hero: null },
    { role: 'Support', hero: null },
    { role: 'Support', hero: null },
  ];

  const [teamSlots, setTeamSlots] = useState(initialSlots);
  const [selectedMap, setSelectedMap] = useState<string>(maps[0]);
  const [selectedRole, setSelectedRole] = useState<'Tank' | 'Damage' | 'Support'>('Damage');
  const [suggestions, setSuggestions] = useState<HeroSuggestion[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoadingOverlay, setIsLoadingOverlay] = useState<boolean>(false);
  const [isHeroModalOpen, setIsHeroModalOpen] = useState<boolean>(false);
  const [selectedSlotIndex, setSelectedSlotIndex] = useState<number | null>(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false); // Added state variable for error modal

  // Variable to control the state of the "Get Suggestions" button
  const isDisabled = isLoading;

  // Variable to control the state of the "Clear" button
  const isClearDisabled = isLoading || teamSlots.filter((slot) => slot.hero).length === 0;

  const openHeroSelectionModal = (index: number) => {
    setSelectedSlotIndex(index);
    setIsHeroModalOpen(true);
  };

  const addHeroToSlotFromModal = (hero: Hero): void => {
    if (selectedSlotIndex !== null && hero.role === teamSlots[selectedSlotIndex].role) {
      const updatedSlots = [...teamSlots];
      updatedSlots[selectedSlotIndex].hero = hero;
      setTeamSlots(updatedSlots);
      setIsHeroModalOpen(false);
      setSelectedSlotIndex(null);
    }
  };

  const clearAllSelections = (): void => {
    const resetSlots = initialSlots.map((slot) => ({ ...slot, hero: null }));
    setTeamSlots(resetSlots);
    setSelectedSlotIndex(null);
    setSuggestions([]);
  };

  const isHeroDisabled = (hero: Hero): boolean => {
    return teamSlots.some((slot) => slot.hero && slot.hero.id === hero.id);
  };

  const sendDataToAPI = async () => {
    if (isDisabled) return; // Prevent sending if the button is disabled

    setIsLoading(true);
    setIsLoadingOverlay(true);

    const enemyHeroes = teamSlots.filter((slot) => slot.hero).map((slot) => slot.hero!.name);

    const data = { map: selectedMap, role: selectedRole.toLowerCase(), enemy_heroes: enemyHeroes };

    const dev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

    const url = dev
      ? 'http://localhost:3001/owtatics/api/v1/hero_suggestion_requests'
      : 'https://runeterra-history-api-9ae76edb08fd.herokuapp.com/owtatics/api/v1/hero_suggestion_requests';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (response.status !== 200) {
        setIsErrorModalOpen(true); // Show error modal when status is not 200
      } else {
        const result: HeroSuggestion[] = await response.json();
        setSuggestions(result);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsErrorModalOpen(true); // Show error modal in case of fetch error
    } finally {
      setIsLoading(false);
      setIsLoadingOverlay(false);
    }
  };

  return (
    <div
      className="h-screen-safe overflow-hidden w-full bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-white flex flex-col items-center justify-center font-sans px-4 sm:px-8 py-4"
      style={{ height: 'calc(100vh - env(safe-area-inset-bottom))' }}
    >
      <h1 className="text-5xl font-extrabold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-pink-600 drop-shadow-lg">
        Owtatics
      </h1>

      <div className="w-full max-w-4xl mx-auto rounded-xl p-6 mb-8">
        {/* Step 1: Select Map */}
        <h2 className="text-2xl font-bold mb-4 text-center">Select Map</h2>
        <div className="flex justify-center mb-8">
          <div className="relative w-full max-w-md">
            <select
              className="appearance-none bg-gray-700 text-white rounded-lg px-4 py-2 w-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={selectedMap}
              onChange={(e) => setSelectedMap(e.target.value)}
            >
              {maps.map((map) => (
                <option key={map} value={map}>
                  {map}
                </option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M10 12a1 1 0 01-.707-.293l-3-3a1 1 0 011.414-1.414L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3A1 1 0 0110 12z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* Step 2: Select Your Role */}
        <h2 className="text-2xl font-bold mb-4 text-center">Select Your Role</h2>
        <div className="flex justify-center space-x-4 mb-8">
          {['Tank', 'Damage', 'Support'].map((role) => (
            <button
              key={role}
              className={`flex flex-col items-center justify-center w-24 h-24 rounded-lg transition-all duration-300 ${
                selectedRole === role ? 'bg-blue-600' : 'bg-gray-700 hover:bg-gray-600'
              }`}
              onClick={() => setSelectedRole(role as 'Tank' | 'Damage' | 'Support')}
            >
              <RoleIcon role={role as 'Tank' | 'Damage' | 'Support'} size={8} />
              <span className="mt-2 text-lg">{role}</span>
            </button>
          ))}
        </div>

        {/* Step 3: Select Enemy Heroes */}
        <h2 className="text-2xl font-bold mb-4 text-center">Select Enemy Heroes</h2>
        <div className="flex justify-center mb-8">
          <div className="flex space-x-2 px-2">
            {teamSlots.map((slot, index) => (
              <div
                key={index}
                className={`w-16 h-16 bg-red-700 rounded-lg flex items-center justify-center cursor-pointer transition-all duration-300 relative ${
                  index === selectedSlotIndex ? 'ring-4 ring-red-500 scale-110 z-10' : 'hover:ring-2 hover:ring-red-400'
                }`}
                onClick={() => openHeroSelectionModal(index)}
              >
                {slot.hero ? (
                  <img src={slot.hero.image} alt={slot.hero.name} className="w-full h-full object-cover rounded-lg" />
                ) : (
                  <RoleIcon role={slot.role} size={6} />
                )}
                {slot.hero && (
                  <button
                    className="absolute top-1 right-1 bg-black bg-opacity-50 text-white rounded-full p-1 hover:bg-opacity-75"
                    onClick={(e) => {
                      e.stopPropagation();
                      const updatedSlots = [...teamSlots];
                      updatedSlots[index].hero = null;
                      setTeamSlots(updatedSlots);
                    }}
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-center space-x-4">
          <button
            className={`bg-blue-500 text-white font-bold py-2 px-6 rounded-lg flex items-center shadow-md transition-all duration-300 ${
              isDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
            }`}
            onClick={sendDataToAPI}
            disabled={isDisabled}
          >
            {isLoading ? (
              <>
                <div className="w-5 h-5 border-t-2 border-white border-solid rounded-full animate-spin mr-2"></div>
                Loading...
              </>
            ) : (
              <>
                Suggestions
                <Send className="ml-2 h-5 w-5" />
              </>
            )}
          </button>

          <button
            className={`bg-red-500 text-white font-bold py-2 px-6 rounded-lg flex items-center shadow-md transition-all duration-300 ${
              isClearDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-600'
            }`}
            onClick={clearAllSelections}
            disabled={isClearDisabled}
          >
            Clear
            <Trash2 className="ml-2 h-5 w-5" />
          </button>
        </div>
      </div>

      {/* Hero Selection Modal */}
      {isHeroModalOpen && selectedSlotIndex !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 p-6 rounded-xl max-w-5xl w-full max-h-[90vh] overflow-y-auto shadow-lg">
            <h2 className="text-2xl font-bold mb-6 text-center">Select a Hero</h2>
            <div className="grid grid-cols-3 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-8 gap-4">
              {heroes
                .filter((hero) => hero.role === teamSlots[selectedSlotIndex].role)
                .map((hero) => (
                  <div
                    key={hero.id}
                    className={`relative bg-gray-700 rounded-lg overflow-hidden cursor-pointer transition-all duration-300 transform ${
                      isHeroDisabled(hero) ? 'opacity-50 cursor-not-allowed' : 'hover:ring-2 hover:ring-orange-400 hover:scale-105'
                    }`}
                    onClick={() => !isHeroDisabled(hero) && addHeroToSlotFromModal(hero)}
                  >
                    <img src={hero.image} alt={hero.name} className="w-full h-full object-cover" />
                    <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-2">
                      <p className="text-sm text-center font-bold text-white drop-shadow-lg truncate">{hero.name}</p>
                    </div>
                  </div>
                ))}
            </div>
            <button
              className="mt-6 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-6 rounded-lg mx-auto block shadow-md"
              onClick={() => setIsHeroModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Hero Suggestions Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 p-6 rounded-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto shadow-lg">
            <h2 className="text-2xl font-bold mb-6 text-center">Hero Suggestions</h2>
            <div className="flex flex-col gap-6">
              {suggestions.map((suggestion, index) => {
                const hero = heroes.find((h) => h.name.toLowerCase() === suggestion.hero.toLowerCase());
                return (
                  <div key={index} className="flex items-start gap-4">
                    {hero ? (
                      <img src={hero.image} alt={hero.name} className="w-24 h-24 object-cover rounded-lg flex-shrink-0 shadow-md" />
                    ) : (
                      <div className="w-24 h-24 bg-gray-500 flex items-center justify-center rounded-lg">
                        <span className="text-white">Image not available</span>
                      </div>
                    )}
                    <div className="flex-grow">
                      <h3 className="text-xl font-bold mb-2">{suggestion.hero}</h3>
                      <p className="text-sm text-gray-300">{suggestion.justification}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <button
              className="mt-6 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg mx-auto block shadow-md"
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {isErrorModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 p-6 rounded-xl max-w-md w-full shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-center">Servidor Ocupado</h2>
            <p className="text-center text-white mb-6">
              Por favor, tente novamente em instantes, pois o servidor está com alta demanda de acessos.
            </p>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg mx-auto block shadow-md"
              onClick={() => setIsErrorModalOpen(false)}
            >
              OK
            </button>
          </div>
        </div>
      )}

      {/* Loading Overlay */}
      {isLoadingOverlay && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-8 rounded-xl shadow-lg">
            <div className="flex flex-col items-center space-y-4">
              <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
              <p className="text-xl font-semibold">Loading suggestions...</p>
            </div>
          </div>
        </div>
      )}

      <footer className="text-center text-xs text-gray-400 mt-4">
        Any feedback?{' '}
        <a href="mailto:iago_gtr@gmail.com" className="underline">
          iagocithgtr@gmail.com
        </a>
      </footer>
    </div>
  );
};

export default Component;
